import logo from "./logo.svg";
import "./App.css";

import Landing from "./pages/Landing";

function App() {
	return <Landing />;
}

export default App;
